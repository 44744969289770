import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";

const UserInfo: React.FC = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      axios.defaults.headers.Authorization = localStorage.getItem("token");
    }
  }, []);
  return (
    <div className="absolute right-0 top-0 text-center" onClick={logout}>
      退出
    </div>
  );
};

export default UserInfo;
