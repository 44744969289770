import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosError,
} from "axios";
import JSON_BIG from "json-bigint";
import { useNavigate } from "react-router-dom";
//基础URL，axios将会自动拼接在url前
//process.env.NODE_ENV 判断是否为开发环境 根据不同环境使用不同的baseURL 方便调试
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// //默认请求超时时间
axios.defaults.timeout = 15000;
axios.defaults.transformResponse = [
  function (data) {
    if (!data) return {}; //上传图片是空
    const json = JSON_BIG({
      storeAsString: true,
    });

    return json.parse(data);
  },
];

axios.interceptors.response.use(
  (config: AxiosResponse) => {
    if (config.data.code === 401004) {
      window.location.href = "/login";
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

//axios返回格式
interface axiosTypes<T> {
  data: T;
  status: number;
  statusText: string;
}

//后台响应数据格式
//###该接口用于规定后台返回的数据格式，意为必须携带code、msg以及result
//###而result的数据格式 由外部提供。如此即可根据不同需求，定制不同的数据格式

const post = <T>(url: string, data: object): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data,
    })
      .then((res: AxiosResponse<T>) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const get = <T>(url: string, data: object): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export { post, get };
