import { post, get } from "./axios";

interface resTypes {
  code: number;
  msg: string;
  data: any;
}

export const login = (data: { username: string; pwd: string }) => {
  return post<resTypes>("/authority/operator/login/do", data);
};
export const gradeList = (data: {
  size?: number;
  grade?: number;
  beginTime?: string;
  endTime?: string;
}) => {
  return post<resTypes>("/ai/graph/grade/list", data);
};

interface GraphGrade {
  aiGraphId: number;
  grade: number;
}

export const gradeUpdate = (data: { graphGrades: GraphGrade[] }) => {
  return post<resTypes>("/ai/graph/grade/update", data);
};
export const getStyleList = (data: {}) => {
  return get<resTypes>("/ai/graph/style/list", data);
};
export const block = (data: { orderId: string }) => {
  return post<resTypes>("/ai/user/block", data);
};
