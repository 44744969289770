import React from "react";
import { Input, Button, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { block } from "../service/api";
import axios from "axios";

const { TextArea } = Input;
const Block: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = (values: any) => {
    const orderIds = values.orderId.split("\n");
    orderIds.map((item: string) => {
      const order = item.replace("`", "");
      block({ orderId: order }).then((res) => {
        if (res.code === 0) {
          messageApi.open({
            type: "success",
            content: order + "，拉黑成功",
          });
        } else {
          messageApi.open({
            type: "error",
            content: order + "，拉黑失败",
          });
        }
      });
    });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="p-5">
      {contextHolder}
      <div className="flex flex-col">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="orderId"
            rules={[{ required: true, message: "请输入order id～" }]}
          >
            <TextArea placeholder="输入order id" rows={10} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Block;
