import React, { useEffect, useState } from "react";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import UserInfo from "../components/UserInfo";

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];
const PIC_HELP_URL =
  "https://am80fqh0vi.feishu.cn/docx/KDQWdC9z4ormgXxLW7YcBe48nJd";
const items: MenuItem[] = [
  { label: "审核图片", key: "/pic", icon: <EditOutlined /> },
  { label: "第二轮审核", key: "/pic2", icon: <EditOutlined /> },
  { label: "退款拉黑", key: "/block", icon: <EditOutlined /> },
];

const App: React.FC = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const jumpToHandler: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };
  return (
    <Layout className="h-full">
      <UserInfo />
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          onClick={jumpToHandler}
        />
        <ul style={{ paddingLeft: "28px" }}>
          <li className="ant-menu-item text-left">
            <QuestionCircleOutlined
              style={{ color: "rgba(255, 255, 255, 0.65)" }}
            />
            <a
              href={PIC_HELP_URL}
              target="_blank"
              style={{
                marginInlineStart: "10px",
                color: "rgba(255, 255, 255, 0.65)",
              }}
            >
              审核使用说明
            </a>
          </li>
        </ul>
      </Sider>
      <Layout>
        <Content>
          <div className="bg-white p-0.67 h-full ">
            <Outlet></Outlet>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
