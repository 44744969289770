import React, { useEffect, useState } from "react";
import { gradeList, gradeUpdate, getStyleList } from "../service/api";
import { message } from "antd";
import ImageZoom from "../components/ImageZoom";
import { useSearchParams } from "react-router-dom";

interface DetailKey {
  id: number;
  content: string;
  grade: number;
  userPrompt: string;
  setting: string;
  checked: boolean;
}

interface StyleType {
  styleId: number;
  styleName: string;
}

enum markType {
  good = 20,
  normal = 10,
  bad = -1,
  yellow = -2,
}

const Detail: React.FC = () => {
  const [params] = useSearchParams();
  const size = params.get("size") ? Number(params.get("size")) : 10;
  const [list, setList] = useState<DetailKey[]>([]);
  const [styleList, setStyleList] = useState<StyleType[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const markHandler = (item: DetailKey) => {
    const newData = list.map((i) => {
      if (i === item) {
        return { ...i, checked: !item.checked };
      }
      return i;
    });
    setList(newData);
  };

  const submitHandler = (type: markType) => {
    const newData = list.map((i) => {
      if (i.checked) {
        return {
          ...i,
          grade: type,
          checked: false,
        };
      }
      return { ...i };
    });
    setList(newData);
    updateData(newData);
  };
  const updateData = (newData: DetailKey[]) => {
    const graphGrades: Array<{ aiGraphId: number; grade: number }> = [];
    newData.map((item) => {
      graphGrades.push({ aiGraphId: item.id, grade: item.grade });
    });
    console.info(graphGrades);
    gradeUpdate({ graphGrades }).then((res) => {
      if (res.code === 0) {
        messageApi.open({
          type: "success",
          content: "提交成功",
        });
      } else {
        messageApi.open({
          type: "error",
          content: res.msg,
        });
      }
    });
  };

  const getData = () => {
    gradeList({ size, grade: 5 }).then((res) => {
      if (res.code === 0) {
        setList(res.data.list);
      } else {
        messageApi.open({
          type: "error",
          content: res.msg,
        });
      }
    });
  };

  const getStyleListData = () => {
    return getStyleList({}).then((res) => {
      if (res.code === 0) {
        return res.data.list;
      } else {
        messageApi.open({
          type: "error",
          content: res.msg,
        });
      }
    });
  };
  useEffect(() => {
    Promise.all([
      gradeList({ size, grade: 5 }).then((res) => {
        if (res.code === 0) {
          return res.data.list;
        } else {
          messageApi.open({
            type: "error",
            content: res.msg,
          });
        }
      }),
      getStyleListData(),
    ]).then((res) => {
      setStyleList(res[1]);
      setList(res[0]);
    });
  }, []);

  return list.length > 0 ? (
    <div className="h-full flex flex-col items-center justify-center bg-amber-100">
      {contextHolder}
      <div className=" flex items-center">
        <div className="flex flex-wrap justify-center items-center">
          {list.map((item) => {
            return (
              <ImageZoom
                key={item.id}
                styleList={styleList}
                item={item}
                markHandler={markHandler}
              />
            );
          })}
        </div>
      </div>
      <div className="flex fixed bottom-0 flex justify-center text-5xl">
        <div
          className="text-yellow-600"
          onClick={() => submitHandler(markType.yellow)}
        >
          审核为违规
        </div>
        <div
          className="text-red-600 ml-10"
          onClick={() => submitHandler(markType.bad)}
        >
          审核为坏
        </div>
        <div
          className="text-blue-600 ml-10"
          onClick={() => submitHandler(markType.normal)}
        >
          审核为普通
        </div>
        <div
          className="text-green-600 ml-10"
          onClick={() => submitHandler(markType.good)}
        >
          审核为好
        </div>
        <div className="ml-10" onClick={getData}>
          下一批
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">无数据</div>
  );
};

export default Detail;
