import React, { useState, useEffect } from "react";
import { ZoomInOutlined } from "@ant-design/icons";

import { Tooltip } from "antd";

interface StyleType {
  styleId: number;
  styleName: string;
}

interface DetailKey {
  id: number;
  content: string;
  grade: number;
  userPrompt: string;
  setting: string;
  checked: boolean;
}

const ImageZoom = (props: {
  item: DetailKey;
  styleList: StyleType[];
  markHandler: Function;
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const imageRef: any = React.createRef();

  useEffect(() => {
    if (isZoomed) {
      const rect = imageRef.current.getBoundingClientRect();
      const s: number = window.innerHeight / imageRef.current.offsetHeight;
      setPosition({
        x:
          window.innerWidth / 2 -
          imageRef.current.offsetWidth / 2 -
          rect.left -
          window.scrollX,
        y:
          window.innerHeight / 2 -
          imageRef.current.offsetHeight / 2 -
          rect.top -
          window.scrollY,
      });
      setScale(s);
    } else {
      setPosition({
        x: 0,
        y: 0,
      });
      setScale(1);
    }
  }, [isZoomed]);

  const markHandler = () => {
    if (isZoomed) {
      setIsZoomed(false);
    } else {
      props.markHandler(props.item);
    }
  };
  const getStyleName = (setting: string) => {
    const id = JSON.parse(setting).styleId;
    const item = props.styleList.find((item) => item.styleId === id);
    return item ? item.styleName : "无";
  };
  return (
    <div
      className={
        "relative m-0.5 border-8 border-solid " +
        (props.item.checked ? "border-red-600" : "border-white")
      }
    >
      <img
        title={props.item.userPrompt}
        alt={"图片错误"}
        onClick={markHandler}
        src={JSON.parse(props.item.content).images[0].url}
        className={
          "relative block h-60 transition-all " + (isZoomed ? "z-40" : "z-10")
        }
        ref={imageRef}
        style={{
          transform: ` translate(${position.x}px, ${position.y}px) scale(${scale})`,
        }}
      />
      <Tooltip
        title={getStyleName(props.item.setting) + "：" + props.item.userPrompt}
      >
        <div
          style={{ textShadow: "1px 1px 3px #999" }}
          className="text-shadow absolute right-1 top-0 z-20 text-right text-2xl text-white bg-opacity-20"
        >
          {props.item.grade}
        </div>
      </Tooltip>
      <ZoomInOutlined
        onClick={() => setIsZoomed(true)}
        className="text-3 text-white  z-20 absolute bottom-0 right-0 opacity-30"
      />
      {isZoomed ? (
        <div
          onClick={() => setIsZoomed(false)}
          className="fixed z-30 inset-0 w-full h-full bg-black opacity-50"
        ></div>
      ) : null}
    </div>
  );
};

export default ImageZoom;
