import { createBrowserRouter } from "react-router-dom";
import App from "../pages/App";
import About from "../pages/About";
import Pic from "../pages/Pic";
import Pic2 from "../pages/Pic2";
import Block from "../pages/Block";
import Login from "../pages/Login";
import React from "react";

export default createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/about", element: <About />, id: "关于" },
      { path: "/pic", element: <Pic /> },
      { path: "/pic2", element: <Pic2 /> },
      { path: "/block", element: <Block /> },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
